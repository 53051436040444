import React, { useEffect, useState, useRef } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link } from "gatsby";
import LangSelect from "../LangSelect"
import { handleLinkClick } from "../../helperFunctions";

const HeaderEn = () => {
  // const [productsOpen, setproductsOpen] = useState(false)
  // const [resourcesOpen, setresourcesOpen] = useState(false)
  // const [clientsOpen, setclientsOpen] = useState(false)
  const [mobileDropdown, setmobileDropdown] = useState(false)
  const [mobileProductsOpen, setmobileProductsOpen] = useState(false)
  const [mobileResourcesOpen, setmobileResourcesOpen] = useState(false)
  const [mobileClientsOpen, setmobileClientsOpen] = useState(false)
  const [mobileCompanyOpen, setmobileCompanyOpen] = useState(false)
  const [ProductsDropdown, setProductsDropdown] = useState(false)
  const [clientsDropdown, setclientsDropdown] = useState(false)
  const [resourcesDropdown, setresourcesDropdown] = useState(false)
  const [companyDropdown, setcompanyDropdown] = useState(false)
  // const prods = useRef(null);

  // const handleProductsHover = () => {
  //   setproductsOpen(!productsOpen)
  // }

  // const handleResourcesHover = () => {
  //   setresourcesOpen(!resourcesOpen)
  // }

  // const handleClientsHover = () => {
  //   setclientsOpen(!clientsOpen)
  // }

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }

  const handleMobileProducts = () => {
    setmobileProductsOpen(!mobileProductsOpen)
    setmobileResourcesOpen(false)
    setmobileClientsOpen(false)
    setmobileCompanyOpen(false)
    // console.log(prods.current);
    // const div = prods.current
    // div.className = "hidden" ? div.className = "bg-purple-100 rounded-xs py-1 px-3 flex flex-col items-start w-full dropdown overflow-y-scroll" : div.className = "hidden"
  }

  const handleMobileResources = () => {
    setmobileResourcesOpen(!mobileResourcesOpen)
    setmobileProductsOpen(false)
    setmobileClientsOpen(false)
    setmobileCompanyOpen(false)
  }

  const handleMobileClients = () => {
    setmobileClientsOpen(!mobileClientsOpen)
    setmobileProductsOpen(false)
    setmobileCompanyOpen(false)
    setmobileResourcesOpen(false)
  }
  
  const handleMobileCompany = e => {
    setmobileProductsOpen(false)
    setmobileClientsOpen(false)
    setmobileResourcesOpen(false)
    setmobileCompanyOpen(!mobileCompanyOpen)
  }

  const toggleProducts = e => {
    setclientsDropdown(false)
    setresourcesDropdown(false)
    setmobileCompanyOpen(false)
    setProductsDropdown(!ProductsDropdown)
  }

  const toggleClients = e => {
    setProductsDropdown(false)
    setresourcesDropdown(false)
    setmobileCompanyOpen(false)
    setclientsDropdown(!clientsDropdown)
  }

  const toggleResources = e => {
    setProductsDropdown(false)
    setclientsDropdown(false)
    setmobileCompanyOpen(false)
    setresourcesDropdown(!resourcesDropdown)
  }

  const toggleCompanies = e => {
    setProductsDropdown(false)
    setclientsDropdown(false)
    setresourcesDropdown(false)
    setcompanyDropdown(!companyDropdown)
  }

  const closeProductDropdown = e => {
    if (e.target.parentNode.id === "prodDrop") {
      setProductsDropdown(true)
      // console.log(e.target.parentNode.id === 'prodDrop', e.target.parentNode.id);
    } else {
      setProductsDropdown(false)
    }
  }
  const closeclientDropdown = e => {
    if (e.target.parentNode.id === "clientDrop") {
      setclientsDropdown(true)
      // console.log(e.target.parentNode.id === 'prodDrop', e.target.parentNode.id);
    } else {
      setclientsDropdown(false)
    }
  }
  const closeResourceDropdown = e => {
    if (e.target.parentNode.id === "resDrop") {
      setresourcesDropdown(true)
      // console.log(e.target.parentNode.id === 'prodDrop', e.target.parentNode.id);
    } else {
      setresourcesDropdown(false)
    }
  }

  const  closeCompanyDropdown = e => {
    if (e.target.parentNode.id === "companyDrop") {
      setcompanyDropdown(true)
      // console.log(e.target.parentNode.id === 'prodDrop', e.target.parentNode.id);
    } else {
      setcompanyDropdown(false)
    }
  }


  useEffect(() => {
    if (ProductsDropdown) {
      document.addEventListener("click", closeProductDropdown)
    }

    return () => {
      document.removeEventListener("click", closeProductDropdown)
    }
  }, [ProductsDropdown])

  useEffect(() => {
    if (clientsDropdown) {
      document.addEventListener("click", closeclientDropdown)
    }

    return () => {
      document.removeEventListener("click", closeclientDropdown)
    }
  }, [clientsDropdown])

  useEffect(() => {
    if (resourcesDropdown) {
      document.addEventListener("click", closeResourceDropdown)
    }

    return () => {
      document.removeEventListener("click", closeResourceDropdown)
    }
  }, [resourcesDropdown])
  useEffect(() => {
    if (companyDropdown) {
      document.addEventListener("click", closeCompanyDropdown)
    }

    return () => {
      document.removeEventListener("click", closeCompanyDropdown)
    }
  }, [companyDropdown])


  return (
    <div className="w-full bg-white flex flex-col xl:flex-row place-content-center z-50 py-2">
      <div className="w-full xl:w-11/12 2xl:max-w-7xl flex flex-col lg:flex-row justify-between px-6 xl:px-2 items-center relative">
        <div className="w-2/3 lg:w-1/4 py-3 lg:py-3">
          <a href="/">
            <img
              className="w-auto vc-logo transform md:scale-50 lg:scale-100 origin-left"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/new-vc-logo.png"
              alt="Vantage Circle Logo"
              width="250"
              height="40"
            />
          </a>
        </div>
        <div className="hidden lg:flex justify-end 2xl:justify-start items-center w-1/3 lg:w-11/12 2xl:w-full">
          <div className="w-7/12 lg:w-10/12 xl:w-8/12 lg:pr-5 xl:pl-8 xl:pr-0 grid grid-cols-5 justify-around items-center">
            <div id="solutions-tab" className="py-7 text-base text-indigo-100 flex justify-start items-center m-0 cursor-pointer w-full mx-3 hover:text-orange z-50 h-full products" onClick={toggleProducts}>
              <p className= "tab-name m-0 p-0 text-base text-black hover:text-newOrange">Solutions{" "}</p>
              <svg
                className="h-5 w-5 text-indigo-100 group-hover:text-orange py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <div id="prodDrop" className={ProductsDropdown ? "submenu-1 lg:w-11/12 xl:w-10/12 flex lg:left-6 xl:left-24 2xl:left-20 ml-2 2xl:ml-0 shadow-purple mx-auto flex": "hidden"}>
                  <div className="bg-white w-full rounded-xl">
                      <div className="grid grid-cols-2 w-full">
                              <div className="mx-auto gap-5 my-4 ml-3 mr-3 py-3 px-0">
                                <p className="text-3xl font-bold text-indigo-100 pl-5 mb-3">Products</p>
                                  <div className="grid grid-cols-2">
                                      <Link
                                        to="/products/rewards-recognition/"
                                        onClick={(e) => handleLinkClick(e, '/products/rewards-recognition/')}
                                        activeClassName= "nav-active"
                                        className="flex p-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                      >
                                        <div className="flex items-center">
                                          <img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/Rewards-ico.png"
                                            className="transform scale-75"
                                            alt="Vantage Rewards"
                                            width="30"
                                            height="5"
                                          />
                                          <p className="text-gray-200 text-base m-0 font-bold">
                                            <span className="text-gray-250">
                                              &nbsp;&nbsp;Vantage{" "}
                                            </span>
                                            Rewards
                                          </p>
                                        </div>
                                        <p className="text-gray-200 text-base m-0 pl-10">
                                          A people first rewards and recognition platform to
                                          elevate company culture.
                                        </p>
                                      </Link>

                                      <Link
                                        to="/products/employee-surveys/"
                                        onClick={(e) => handleLinkClick(e, '/products/employee-surveys/')}
                                        activeClassName= "nav-active"
                                        className="flex p-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                      >
                                        <div className="flex items-center">
                                          <img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/pulse-ico-1.png"
                                            className="transform scale-75"
                                            alt="Vantage Rewards"
                                            width="30"
                                            height="5"
                                          />
                                          <p className="text-gray-200 text-base m-0 font-bold">
                                            <span className="text-gray-250">
                                              &nbsp;&nbsp;Vantage{" "}
                                            </span>
                                            Pulse
                                          </p>
                                        </div>
                                        <p className="text-gray-200 text-base m-0 pl-10">
                                          An eNPS-based pulse survey tool that empowers HRs to
                                          manage the workforce better.
                                        </p>
                                      </Link>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <Link
                                      to="/products/employee-discount/"
                                      onClick={(e) => handleLinkClick(e, '/products/employee-discount/')}
                                      activeClassName= "nav-active"
                                      className="flex p-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                  
                                        
                                      >
                                        <div className="flex items-center">
                                          <img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/Perks-ico.png"
                                            className="transform scale-75"
                                            alt="Vantage Rewards"
                                            width="30"
                                            height="5"
                                          />
                                          <p className="text-gray-200 text-base m-0 font-bold">
                                            <span className="text-gray-250">
                                              &nbsp;&nbsp;Vantage{" "}
                                            </span>
                                            Perks
                                          </p>
                                        </div>
                                        <p className="text-gray-200 text-base m-0 pl-10">
                                          A corporate discounts platform with a plethora of
                                          exclusive deals and offers from global brands.
                                        </p>
                                      </Link>
                                      <a
                                        href="https://www.vantagefit.io/"
                                        className="flex p-3 justify-start rounded-md hover:bg-lightOrange flex-col"
                                      >
                                        <div className="flex items-center">
                                          <img
                                            src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/vfit-ico.png"
                                            className="transform scale-75"
                                            alt="Vantage Rewards"
                                            width="30"
                                            height="5"
                                          />
                                          <p className="text-base text-gray-200 m-0 font-bold">
                                            <span className="text-gray-250">
                                              &nbsp;&nbsp;Vantage{" "}
                                            </span>
                                            Fit
                                          </p>
                                        </div>
                                        <p className="text-gray-200 text-base m-0 pl-10">
                                          A gamified corporate wellness platform that keeps the
                                          workforce ‘Fit’ and rewards them for it.
                                        </p>
                                      </a>
                                  </div>
                              </div>
                              <div className="flex flex-col py-6 px-3 border-l border-gray-150">
                              <p className="text-3xl font-bold text-indigo-100 pl-6 mb-3 mt-1">Services</p>
                                  <a
                                    href="/services/vantage-swags-corporate-gifting/"
                                    activeClassName= "nav-active"
                                    className="flex pb-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                  >
                                    <div className="pt-4 px-5 flex w-full items-center">
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-gifting.webp"
                                        alt="Product Updates" width="28"
                                      />
                                      <p className="text-base w-full m-0 text-gray-250 font-bold pl-3">
                                        &nbsp;&nbsp;Vantage Swags
                                      </p>
                                    </div>
                                    <p className="text-gray-200 text-base m-0 pl-12 ml-5 mt-0">
                                    An all-in-one corporate gifting solution to delight your employees on every occasion 
                                        & make them feel valued.
                                    </p>
                                  </a>
                                  
                                  <a
                                    href="/services/aire-consultation/"
                                    activeClassName= "nav-active"
                                    className="flex pb-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                  >
                                    <div className="pt-3 px-5 flex w-full items-center">
                                      <img
                                        src="https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/Homepage-AIRe.webp"
                                        alt="Product Updates" width="45"
                                      />
                                      <p className="text-base w-full m-0 text-gray-250 font-bold transform -translate-x-1">
                                        &nbsp;&nbsp;AIR<sup>e</sup> Consultation
                                      </p>
                                    </div>
                                    <p className="text-gray-200 text-base m-0 pl-12 ml-5 mt-0">
                                    AIR<sup>e</sup> program consultation to design and implement an authentic and impactful rewards and recognition program.
                                    </p>
                                  </a>                         
                                </div>
                          </div>
                          <div className="py-0 px-3 xl:px-0 bg-purple-100 rounded-b-xl">
                              <div className="grid grid-cols-2">
                                  <a
                                  href="/integration/"
                                  activeClassName= "nav-active"
                                  className="flex pb-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                                  >
                          <div className="pt-3 px-5 flex w-full items-center">
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/INTIGRATION.png" width="35"
                              alt="Integration"
                            />
                            <p className="text-base w-full m-0 text-gray-250 font-bold pl-0">
                              &nbsp;&nbsp;Integration
                            </p>
                          </div>
                          <p className="text-gray-200 text-base m-0 pl-12 ml-5 -mt-1">
                            Seamless integration with your existing HCM/HRIS platform
                            and chat tools.
                          </p>
                        </a>

                        <a
                          href="/product-updates/"
                          activeClassName= "nav-active"
                          className="flex pb-3 justify-start rounded-md hover:bg-white hover:rounded-lg hover:bg-lightOrange flex-col"
                        >
                          <div className="pt-3 px-5 flex w-full items-center">
                            <img
                              src="https://res.cloudinary.com/vantagecircle/image/upload/w_36/v1671709108/gatsbycms/uploads/2023/05/PRODUCT_UPDATE.png" width= "40"
                              alt="Product Updates"
                            />
                            <p className="text-base w-full m-0 text-gray-250 font-bold pl-1">
                              &nbsp;&nbsp;Product Updates
                            </p>
                          </div>
                          <p className="text-gray-200 text-base m-0 pl-12 ml-5 -mt-1">
                            Check out all the new stuff we are adding to our products
                            to constantly improve them for better experience.
                          </p>
                        </a>

                              </div>
                          </div>                
                  </div>                  
                </div>    
            </div>
            <div
              className="relative py-7 text-base text-indigo-100 flex justify-center items-center m-0 cursor-pointer mx-3 lg:pl-4 xl:pl-0 xl:pr-4 hover:text-orange z-50 h-full clients"
              onClick={toggleClients} id="client-tab"
            >
              <p className="tab-name m-0 p-0 text-base text-black hover:text-newOrange">Clients{" "}</p>
              <svg
                className="h-5 w-5 text-indigo-100 group-hover:text-orange py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <div
                id="clientDrop"
                className={clientsDropdown ? "submenu-2" : "hidden"}
                style={{ left: "0%", width: "250px" }}
              >
                <div className="flex flex-col py-6 px-3 w-full">
                  <a
                    href="/corporates/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                     <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CLIENTOVERVIEW.png" width="30" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Clients Overview
                    </p>
                  </a>
                  <a
                    href="/case-study/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/SUCCESS_STORIES.png" width="30" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Success Stories
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="relative py-7 text-base text-indigo-100 rounded-xl flex justify-start items-center m-0 cursor-pointer w-full mx-3 lg:pr-2 xl:pr-0 hover:text-orange z-50 h-full resources"
              onClick={toggleResources} id="resource-tab"
            >
             <p className="tab-name m-0 p-0 text-base text-black hover:text-newOrange">Resources{" "}</p> 
              <svg
                className="h-5 w-5 text-indigo-100 group-hover:text-gray-light py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <div
                id="resDrop"
                className={resourcesDropdown ? "submenu-3" : "hidden"}
                style={{ left: "0%", width: "600px" }}
              >
                <div className="flex-col py-6 px-3 bg-white rounded-xl">
                  <a
                    href="https://www.vantagecircle.com/en/blog/"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/BLOG.png"
                      className="transform scale-75"
                      alt="Vantage Rewards"
                      width="37"
                    />
                    <p className="text-base m-0 transform -translate-x-0 text-gray-250">
                      &nbsp;&nbsp; Blog
                    </p>
                  </a>
                  <a
                    href="https://www.vantagecircle.com/en/blog/podcasts/"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/PODCAST.png"
                      className="transform scale-75"
                      alt="Vantage Rewards"
                      width="34"
                      height="5"
                    />
                    <p className="text-base m-0 pl-2 text-gray-250">
                      &nbsp;&nbsp;Influencers Podcast
                    </p>
                  </a>
                  <a
                    href="/resources/"
                    activeClassName= "nav-active"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/GUIDEEBOOKS.png"
                      className="transform scale-75"
                      alt="Vantage Rewards"
                      width="34"
                      height="5"
                    />
                    <p className="text-base m-0 pl-2 text-gray-250">
                      &nbsp;&nbsp;Guides & eBooks
                    </p>
                  </a>
                  <a
                    href="/webinars/"
                    activeClassName= "nav-active"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/WEBINARS.png"
                      className="transform scale-75"
                      alt="Vantage Rewards"
                      width="37"
                      height="5"
                    />
                    <p className="text-base m-0 pl-2 text-gray-250">
                      &nbsp;&nbsp;Webinars
                    </p>
                  </a>
                  <a
                    href="/hr-academy/industry-reports/"
                    activeClassName= "nav-active"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/INDUSTRY_REPORTS.png"
                      className="transform scale-75"
                      alt="Vantage Rewards"
                      width="33"
                      height="5"
                    />
                    <p className="text-base m-0 pl-3 2xl:pl-2 text-gray-250">
                      &nbsp;&nbsp;Industry Reports
                    </p>
                  </a>
                  <a
                    href="/glossaries/"
                    activeClassName= "nav-active"
                    className="flex p-1 items-center py-3 rounded-md hover:bg-lightOrange"
                  >
                    <img
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2024/07/HRglossary-ico-1.png"
                      className="transform scale-75"
                      alt="Glossary"
                      width="33"
                      height="5"
                    />
                    <p className="text-base m-0 pl-3 2xl:pl-2 text-gray-250">
                      &nbsp;&nbsp;HR Glossaries
                    </p>
                  </a>
                </div>
                <div className="flex flex-col py-6 px-3 bg-purple-100 rounded-r-xl">
                  <div className="text-gray-250 py-3 flex w-full items-center justify-start flex flex-col">
                    <div className="flex justify-start w-full">
                      <img
                        src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/10/aire-ico.png"
                        height="10"
                        width="40"
                      />
                      <p className="text-base text-gray-250 w-full m-0 pb-0 border-b border-gray-600">
                        &nbsp;&nbsp;AIR<sup>e</sup> Framework
                      </p>
                    </div>

                    <div className="flex flex-col w-full pl-8 py-4">
                      <Link
                        to="/annual-rewards-and-recognition-benchmarking-report-UAE-2024/"
                        onClick={(e) => handleLinkClick(e, '/annual-rewards-and-recognition-benchmarking-report-UAE-2024/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"       
                      >
                        &gt;&gt;&nbsp;R&R Report - UAE(2024-25)
                      </Link>
                      <Link
                        to="/annual-rewards-and-recognition-benchmarking-report-india-2024/"
                        onClick={(e) => handleLinkClick(e, '/annual-rewards-and-recognition-benchmarking-report-india-2024/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"       
                      >
                        &gt;&gt;&nbsp;R&R Report - India(2024-25)
                      </Link>
                      <Link
                        to="/aire-benchmarking-report-united-states/"
                        onClick={(e) => handleLinkClick(e, '/aire-benchmarking-report-united-states/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"
                      >
                        &gt;&gt;&nbsp;R&R Report - US(2023-24)
                      </Link>
                      <Link
                        to="/aire-benchmarking-report-uk/"
                        onClick={(e) => handleLinkClick(e, '/aire-benchmarking-report-uk/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"
                      >
                        &gt;&gt;&nbsp;R&R Report - UK(2023-24)
                      </Link>
                      <Link
                        to="/aire-benchmarking-report-india/"
                        onClick={(e) => handleLinkClick(e, '/aire-benchmarking-report-india/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"
                      >
                        &gt;&gt;&nbsp;R&R Report - India(2023-24)
                      </Link>
                      <Link
                        to="/aire-benchmarking-report/"
                        onClick={(e) => handleLinkClick(e, '/aire-benchmarking-report/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"       
                      >
                        &gt;&gt;&nbsp;R&R Report - Global(2022-23)
                      </Link> 
                      
                      <div className="my-3 border-t border-gray-600 w-full"></div> 
                      <Link
                        to="/hr-academy/whitepapers/aire-framework/"
                        onClick={(e) => handleLinkClick(e, '/hr-academy/whitepapers/aire-framework/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"                        
                      >
                        &gt;&gt;&nbsp;AIR<sup>e</sup> Whitepaper
                      </Link> 
                      <div className="my-3 border-t border-gray-600 w-full"></div>           
                      <Link
                        to="/tools/aire-score-assessment/"
                        onClick={(e) => handleLinkClick(e, '/tools/aire-score-assessment/')}
                        activeClassName= "nav-active"
                        className="py-2 text-gray-250 hover:text-purple-200 transform hover:translate-x-4 translate-x-0 duration-500"                       
                      >
                        &gt;&gt;&nbsp;R&R Program Assessment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Link
                to="/plans-and-pricing/"
                onClick={(e) => handleLinkClick(e, '/plans-and-pricing/')}
                activeStyle={{ color: '#FF6D05' }}
                className="text-base text-indigo-100 py-7 flex justify-center w-full m-0 cursor-pointer mx-3 lg:pl-5 xl:pl-0 pr-9 hover:text-newOrange"
              >
                Pricing
              </Link>
            </div>
            <div
              className="relative text-base text-indigo-100 flex justify-start w-full items-center m-0 cursor-pointer mx-3 hover:text-orange z-50 h-full clients"
              onClick={toggleCompanies} id="company-tab"
            >
              <p className= "tab-name m-0 p-0 text-base text-black hover:text-newOrange">Company{" "}</p>
              <svg
                className="h-5 w-5 text-indigo-100 group-hover:text-gray-light py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <div
                id="companyDrop"
                className={companyDropdown ? "submenu-2" : "hidden"}
                style={{ left: "0%", width: "250px" }}
              >
                <div className="flex flex-col py-6 px-3 w-full">
                  <a
                    href="/about-us/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/ABOUT_US.png" width="28" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;About us
                    </p>
                  </a>
                  <Link
                    to="/careers/"
                    onClick={(e) => handleLinkClick(e, '/careers/')}
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CAREERS.png" width="28" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Careers
                    </p>
                  </Link>
                  <a
                    href="/employeespeaks/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/10/vc-menu-people-speak-ico.webp" width="30" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Employee Speaks
                    </p>
                  </a>
                  <a
                    href="/strategic-partnership/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/PARTNERSHIP.png" width="28" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Partnership
                    </p>
                  </a>
                  <a
                    href="/contact-us/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CONTACT_US.png" width="28" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Contact us
                    </p>
                  </a>
                  <a
                    href="/about-us/sustainability/"
                    activeClassName= "nav-active"
                    className="py-3 pl-1 flex w-full items-center rounded-md hover:bg-lightOrange"
                  >
                    <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/09/sustainability-ico.webp" width="30" />
                    <p className="text-base w-full m-0 pl-3 text-gray-250 ">
                      &nbsp;&nbsp;Sustainability
                    </p>
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div className="flex px-0 xl:px-2 lg:w-6/12 xl:w-5/12 justify-center xl:justify-between items-center">
            <a
              href="https://app.vantagecircle.com/"
              className="text-newOrange w-4/12 xl:w-3/12 rounded-full border border-newOrange py-2 lg:mx-2 xl:mx-0 transition duration-500 ease-in-out transform hover:shadow hover:-translate-y-1 flex justify-center items-center m-0 text-base xl:mr-2 cursor-pointer"
            >
              Sign In
            </a>
            <a href="/request-demo/" className="w-3/6 vc-btn-orange-round">
              Request a demo
            </a>
            <div className="w-1/6 flex justify-center"><LangSelect /></div>
          </div>
        </div>
        <div className="flex lg:hidden">
          <LangSelect />
        </div>


        <div
          className="flex lg:hidden justify-center items-center relative"
          onClick={showmobileDropdown}
        >
          <div className="rounded-full bg-white p-1 cursor-pointer">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                strokewidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        className={
          mobileDropdown
            ? `overflow-x-hidden overflow-y-scroll  mt-1 md:mt-10 w-11/12 left-4 md:left-7 md:top-16 absolute rounded-lg flex flex-col justify-start items-start p-6 bg-white dropdown mobileHeaderDropdownContainer ` +
              (mobileProductsOpen || mobileResourcesOpen || mobileCompanyOpen
                // || mobileClientsOpen || mobileCompanyOpen
                ? " h-80vh"
                : "h-auto")
            : "hidden"
        }
        style= {{ boxShadow: `1px 1px 10px rgba(0, 0, 0, 0.2)` }}
      >
        <div className="flex flex-col w-full">
          <a
            href="#"
            className="relative flex items-center text-gray-500 my-3"
            onClick={handleMobileProducts}
          >
            Solutions{" "}
            <svg
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <div
            className={
              mobileProductsOpen
                ? "bg-purple-100 rounded-md py-5 px-3 flex flex-col items-start w-full dropdown overflow-y-scroll"
                : "hidden"
            }
          >
            <a href="/products/rewards-recognition/" className="mb-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/Rewards-ico.png"
                  className="transform scale-75"
                  alt="Vantage Rewards"
                  width="30"
                  height="5"
                />
                <p
                  className="text-gray-200 text-base m-0 font-bold"
                >
                  <span className="text-gray-250">&nbsp;Vantage </span>
                  Rewards
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-9">
                A people first rewards and recognition platform to elevate
                company culture.
              </p>
            </a>

            <a href="/products/employee-discount/" className="my-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/Perks-ico.png"
                  className="transform scale-75"
                  alt="Vantage Perks"
                  width="30"
                  height="5"
                />
                <p
                  className="text-gray-200 text-base m-0 font-bold"
                >
                  <span className="text-gray-250">&nbsp;Vantage </span>
                  Perks
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-9">
                A corporate discounts platform with a plethora of exclusive
                deals and offers from global brands.
              </p>
            </a>

            <a href="/products/employee-survey/" className="my-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/pulse-ico-1.png"
                  className="transform scale-75"
                  alt="Vantage Pulse"
                  width="30"
                  height="5"
                />
                <p
                  className="text-gray-200 text-base m-0 font-bold"
                >
                  <span className="text-gray-250">&nbsp;Vantage </span>
                  Pulse
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-9">
                An eNPS-based pulse survey tool that empowers HRs to manage the
                workforce better.{" "}
              </p>
            </a>

            <a   href="https://www.vantagefit.io/" className="my-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/vfit-ico.png"
                  className="transform scale-75"
                  alt="Vantage Fit"
                  width="30"
                  height="5"
                />
                <p
                  className="text-base m-0 text-gray-200 font-bold"
                >
                  <span className="text-gray-250">&nbsp;Vantage </span>Fit
                </p>
              </div>
              
              <p className="text-gray-200 text-base m-0 pl-9">
                A gamified corporate wellness platform that keeps the workforce
                ‘Fit’ and rewards them for it.
              </p>
            </a>

            <a href="/services/vantage-swags-corporate-gifting/" className="my-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-gifting.webp"
                  className="transform scale-75"
                  alt="Vantage Pulse"
                  width="30"
                  height="5"
                />
                <p    
                  className="text-gray-200 text-base m-0 font-bold"
                >
                  <span className="text-gray-250">&nbsp;Vantage Swags </span>
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-9">
              An all-in-one corporate gifting solution to delight your employees on every occasion & make them feel valued.
              </p>
            </a>

            <a href="/services/aire-consultation/" className="my-3 flex flex-col">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow transform  -translate-x-2">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/Homepage-AIRe.webp"
                  className="transform scale-75"
                  alt="Vantage Pulse"
                  width="50"
                  height="5"
                />
                <p               
                  className="text-gray-200 text-base m-0 font-bold transform  -translate-x-3 pl-1"
                >
                  <span className="text-gray-250">&nbsp;AIR<sup>e</sup> Consultation</span>
                  
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-9">
              AIR<sup>e</sup> program consultation to design and implement an authentic and impactful rewards and recognition program.
              </p>
            </a>
            <a href="/integration/" className="my-3">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/INTIGRATION.png" width="30"
                alt="Integration"
                />
                <p
                 
                  className="text-base w-full m-0 hover:text-purple-200 text-gray-250 font-bold"
                >
                  &nbsp;&nbsp;Integration
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-10">
                Seamless integration with your existing HCM/HRIS platform and
                chat tools.
              </p>
            </a>
            <a href="/product-updates/" className="my-3">
              <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
                <img
                  src="https://res.cloudinary.com/vantagecircle/image/upload/w_36/v1671709108/gatsbycms/uploads/2023/05/PRODUCT_UPDATE.png" width= "30"
                  alt="Product Updates"
                />
                <p
                  className="text-base w-full m-0 hover:text-purple-200 text-gray-250 font-bold"
                >
                  &nbsp;&nbsp;Product Updates
                </p>
              </div>
              <p className="text-gray-200 text-base m-0 pl-10">
                Check out all the new stuff we are adding to our products to
                constantly improve them for better experience.
              </p>
            </a>
          </div>
        </div>
        
        <div className="w-full">
          <a
            href="#"
            className="relative flex items-center text-gray-250 my-3"
            onClick={handleMobileClients}
          >
            Clients{" "}
            <svg
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <div
            className={
              mobileClientsOpen
                ? "bg-purple-100 rounded-md py-1 px-3 flex flex-col items-start w-full dropdown overflow-y-scroll"
                : "hidden"
            }
          >
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CLIENTOVERVIEW.png" width="30" />
              <a
                href="/corporates/"
                className="text-base w-full m-0 pl-3 hover:text-purple-200 text-gray-250 "
              >
                &nbsp;&nbsp;Clients Overview
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/SUCCESS_STORIES.png" width="30" />
              <a
                href="/case-study/"
                className="text-base w-full m-0 pl-3 hover:text-purple-200 text-gray-250 "
              >
                &nbsp;&nbsp;Success Stories
              </a>
            </div>
          </div>
        </div>
        
        <a href="/plans-and-pricing/" className="text-gray-250 my-3">
          Pricing
        </a>
        <div className="w-full">
          <a
            href="#"
            style={{ zIndex: "1000" }}
            className="flex items-center text-gray-250 my-3"
            onClick={handleMobileResources}
          >
            Resources{" "}
            <svg
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <div
            className={
              mobileResourcesOpen
                ? `bg-purple-100 rounded-md py-1 px-3 flex flex-col items-start w-full dropdown overflow-y-scroll`
                : "hidden"
            }
          >
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/BLOG.png"
                className="transform scale-75"
                alt="Vantage Circle Blog"
                width="35"
                height="5"
              />
              <a
                href="https://www.vantagecircle.com/en/blog/"
                className="text-base m-0 transform -translate-x-1 text-gray-250"
              >
                &nbsp;&nbsp; Blog
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/PODCAST.png"
                className="transform scale-75"
                alt="Influencers Podcast"
                width="35"
                height="5"
              />
              <a
                href="https://www.vantagecircle.com/en/blog/podcasts/"
                className="text-base m-0 text-gray-250"
              >
                &nbsp;&nbsp;Influencers Podcast
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/GUIDEEBOOKS.png"
                className="transform scale-75"
                alt="Guides & eBooks"
                width="35"
                height="5"
              />
              <a href="/resources/" className="text-base m-0 text-gray-250">
                &nbsp;&nbsp;Guides & eBooks
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/WEBINARS.png"
                className="transform scale-75"
                alt="Webinars"
                width="35"
                height="5"
              />
              <a href="/webinars/" className="text-base m-0 text-gray-250">
                &nbsp;&nbsp;Webinars
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/INDUSTRY_REPORTS.png"
                className="transform scale-75"
                alt="Webinars"
                width="35"
                height="5"
              />
              <a
                href="/hr-academy/industry-reports/"
                className="text-base m-0 text-gray-250"
              >
                &nbsp;&nbsp;Industry Reports
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2024/07/HRglossary-ico-1.png"
                className="transform scale-75"
                alt="Glossary"
                width="35"
                height="5"
              />
              <a
                href="/glossaries/"
                className="text-base m-0 text-gray-250"
              >
                &nbsp;&nbsp;HR Glossaries
              </a>
            </div>
            <div className="flex flex-col py-2 px-3 bg-purple-100">
              <div className="text-gray-250 py-3 flex w-full items-center justify-start flex flex-col">
                <div className="flex justify-start w-full">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/10/aire-ico.png"
                    height="10"
                    width="40"
                  />
                  <p className="text-base w-full m-0 pb-0 border-b border-gray-600">
                    &nbsp;&nbsp;AIR<sup>e</sup> Framework
                  </p>
                </div>

                <div className="flex flex-col w-full pl-8 py-4">
                    <a
                        href="/annual-rewards-and-recognition-benchmarking-report-india-2024/"
                        className="py-2 text-gray-250 hover:text-purple-200"
                      >
                        &gt;&gt;&nbsp;R&R Report - India(2024-25)
                    </a>
                    <a
                        href="https://www.vantagecircle.com/aire-benchmarking-report-united-states/"
                        className="py-2 text-gray-250 hover:text-purple-200"
                      >
                        &gt;&gt;&nbsp;R&R Report - US(2023-24)
                    </a>
                    <a
                        href="https://www.vantagecircle.com/aire-benchmarking-report-uk/"
                        className="py-2 text-gray-250 hover:text-purple-200"
                      >
                        &gt;&gt;&nbsp;R&R Report - UK(2023-24)
                    </a>
                    <a
                        href="https://www.vantagecircle.com/aire-benchmarking-report-india/"
                        className="py-2 text-gray-250 hover:text-purple-200"
                      >
                        &gt;&gt;&nbsp;R&R Report - India(2023-24)
                    </a>
                    <a
                        href="https://www.vantagecircle.com/aire-benchmarking-report/"
                        className="py-2 text-gray-250 hover:text-purple-200"
                      >
                        &gt;&gt;&nbsp;R&R Report - Global(2022-23)
                    </a>
            
                    <div className="my-2 border-t border-gray-600 w-full"></div>
                    <a
                    href="/hr-academy/whitepapers/aire-framework/"
                    className="py-2 text-gray-250 hover:text-purple-200"
                  >
                    &gt;&gt;&nbsp;AIR<sup>e</sup> Whitepaper
                  </a>
                  <div className="my-2 border-t border-gray-600 w-full"></div>
                  <a
                    href="/tools/aire-score-assessment/"
                    className="py-2 text-gray-250 hover:text-purple-200"
                  >
                    &gt;&gt;&nbsp;R&R Program Assessment
                  </a>
                
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
          <a
            href="#"
            className="relative flex items-center text-gray-250 my-6 overflow-y-scroll"
            onClick={handleMobileCompany}
          >
            Company{" "}
            <svg
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <div
            className={
              mobileCompanyOpen
                ? "bg-purple-100 rounded-md py-1 px-3 flex flex-col items-start w-full dropdown overflow-y-scroll"
                : "hidden"
            }
          >
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/ABOUT_US.png" width="30" />
              <a
                href="/about-us/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;About us
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CAREERS.png" width="30" />
              <a
                href="/careers/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;Careers
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/10/vc-menu-people-speak-ico.webp" width="30" />
              <a
                href="/employeespeaks/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;Employee Speaks
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/PARTNERSHIP.png" width="30" />
              <a
                href="/strategic-partnership/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;Partnership
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/05/CONTACT_US.png" width="30" />
              <a
                href="/contact-us/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;Contact us
              </a>
            </div>
            <div className="flex p-1 items-center py-3 hover:bg-white hover:rounded-lg hover:shadow">
            <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2023/09/sustainability-ico.webp" width="30" />
              <a
                href="/about-us/sustainability/"
                className="text-base w-full m-0 hover:text-purple-200 text-gray-250 pl-3"
              >
                &nbsp;&nbsp;Sustainability
              </a>
            </div>
          </div>
        </div>
          <a href="/request-demo/" className="bg-indigo-100 flex place-content-center text-white rounded-full my-2 py-3">
            Request a demo
          </a>
          <div className="w-full flex place-content-center">
            <a
              href="https://app.vantagecircle.com/"
              className="my-2 w-full flex place-content-center text-indigo-100 rounded-full border border-indigo-100 py-2 px-4"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderEn
